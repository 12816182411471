import { all, call, fork, put, takeLeading, takeLatest, cancelled, take, cancel, race } from 'redux-saga/effects';
import _ from 'lodash';
import { flashErrorMessage, flashSuccessMessage, clearMessages } from 'redux-flash';
import { getMethod, updateMethod, createMethod, patchMethod, resolveErrorPromise } from 'helpers';
import {
  GET_PRODUCER_LIST,
  DELETE_PRODUCER,
  GET_PRODUCER,
  MANAGE_PRODUCER,
  REACTIVATE_PRODUCER,
  APPROVE_PRODUCER,
  GET_PRODUCER_POSSIBLE_PARENTS,
  FAILED_MESSAGE,
  MANUAL_APPROVE_PRODUCER,
  RESET,
  GET_PRODUCER_RESTRICTIONS_FORM_CONFIG,
  GET_PRODUCER_RESTRICTIONS,
  MANAGE_PRODUCER_RESTRICTIONS,
  APPROVE_PRODUCER_NIPR,
  APPROVE_PRODUCER_FFM,
  GET_ALL_PRODUCERS_LIST,
  COPY_TO_CLIPBOARD,
  PRODUCER_TEMPLATE_IMPORT_CSV,
  GET_ALL_PRODUCERS_AND_AGENCIES_LIST,
  GET_AGENT_REP_PRODUCER_RESTRICTIONS_FORM_CONFIG,
  GET_AGENT_REP_PRODUCER_RESTRICTIONS,
} from 'constants/actionTypes';
import { SUCCESS, FAILED } from 'helpers/actionTypesUtils';
import {
  getProducerList,
  getProducerListSuccess,
  getProducerListFailed,
  getProducerSuccess,
  getProducerFailed,
  manageProducerSuccess,
  manageProducerFailed,
  deleteProducerSuccess,
  deleteProducerFailed,
  reactivateProducerSuccess,
  reactivateProducerFailed,
  getLocation,
  getProducerPossibleParents,
  getProducerPossibleParentsSuccess,
  getProducerPossibleParentsFailed,
  getAgency,
  approveProducerSuccess,
  approveProducerFailed,
  manualApproveProducerSuccess,
  manualApproveProducerFailed,
  getProducerRestrictionsFormConfigSuccess,
  getProducerRestrictionsFormConfigFailed,
  manageProducerRestrictionsSuccess,
  manageProducerRestrictionsFailed,
  approveProducerNIPRSuccess,
  approveProducerNIPRFailed,
  approveProducerFFMSuccess,
  approveProducerFFMFailed,
  getAllProducersListSuccess,
  getAllProducersListFailed,
  getAllProducersList,
  producerTemplateImportCsvSuccess,
  producerTemplateImportCsvFailed,
  getAllProducersAndAgenciesListSuccess,
  getAllProducersAndAgenciesListFailed,
  getAgentRepProducerRestrictionsFormConfigFailed,
  getAgentRepProducerRestrictionsFormConfigSuccess
} from 'redux/actions';

function* producerGetList(action) {
  const abortController = new AbortController();

  try {
    yield put(getAgency(action.payload.agencyId));
    yield take('GET_AGENCY_SUCCESS');

    let request = `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/v1/agency/${action.payload.agencyId}/producer?include=person,agency,agentNumbers,user.roles.routes`;

    const params = {
      ...action.payload.pagination,
      ...action.payload.order,
      ...action.payload.filters,
    };

    const paramString = new URLSearchParams(params).toString();

    if (paramString.length) {
      request += `&${paramString}&withPagination=true`;
    }

    const response = yield call(getMethod, request, abortController.signal);
    yield put(getProducerListSuccess(response));
  } catch (error) {
    const message = yield call(resolveErrorPromise, error);
    yield put(message?.detail ? flashErrorMessage(message.detail) : flashErrorMessage(FAILED_MESSAGE));
    yield put(getProducerListFailed());
  } finally {
    if (yield cancelled()) {
      abortController.abort();
    }
  }
}

function* allProducerGetList(action) {
  const abortController = new AbortController();
  try {
    let request = `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/v1/producers?include=person.contacts,agency,user`;
    let params = {};
    params = { ...params, ...action.payload.filters, ...action.payload.pagination, ...action.payload.order };
    const paramString = new URLSearchParams(params).toString();
    if (paramString.length) {
      request += `&${paramString}`;
    }
    const response = yield call(getMethod, request, abortController.signal);
    yield put(getAllProducersListSuccess(response));
  } catch (error) {
    const message = yield call(resolveErrorPromise, error);
    yield put(message?.detail ? flashErrorMessage(message.detail) : flashErrorMessage(FAILED_MESSAGE));
    yield put(getAllProducersListFailed());
  } finally {
    if (yield cancelled()) {
      abortController.abort();
    }
  }
}

function* allProducersAndAgenciesGetList(action) {
  const abortController = new AbortController();
  try {
    let request = `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/v1/agent-rep?include=person,agentNumbers,agency,person.contacts,person.addresses,agency.address`;
    let params = {};
    params = { ...params, ...action.payload.filters, ...action.payload.pagination, ...action.payload.order };
    const paramString = new URLSearchParams(params).toString();
    if (paramString.length) {
      request += `&${paramString}`;
    }
    const response = yield call(getMethod, request, abortController.signal);
    yield put(getAllProducersAndAgenciesListSuccess(response));
  } catch (error) {
    const message = yield call(resolveErrorPromise, error);
    yield put(message?.detail ? flashErrorMessage(message.detail) : flashErrorMessage(FAILED_MESSAGE));
    yield put(getAllProducersAndAgenciesListFailed());
  } finally {
    if (yield cancelled()) {
      abortController.abort();
    }
  }
}

function* agentRepProducerGet(context, action) {
  const abortController = new AbortController();

  try {
    const { agencyId, producerId } = action.payload;

    if (action.payload.callback) {
      yield fork(action.payload.callback, agencyId, producerId);
      yield race({
        success: take(SUCCESS(GET_AGENT_REP_PRODUCER_RESTRICTIONS_FORM_CONFIG)), //
        failed: take(FAILED(GET_AGENT_REP_PRODUCER_RESTRICTIONS_FORM_CONFIG)),
      }); // wait for succes response before continuing...
    }

    const request = ((condition) => {
      switch (condition) {
        case 'restrictions':
          return `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/v1/agent-rep-agency/${agencyId}/producer/${producerId}?include=person.proof,person.addresses,person.contacts,agentNumbers,producerStates,producerRestrictedIssuers,producerApis.producerApiLineOfCoverages.apiLineOfCoverage.lineOfCoverage,producerApis.producerApiHiddenPlans,producerApis.api,producerFfmChecks,producerGaChecks,agentNumbers,agency,user.oktaToken`;
        case 'edit':
        default:
          return `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/v1/agent-rep-agency/${agencyId}/producer/${producerId}?include=person.proof,person.addresses,person.contacts,agentNumbers,user.roles.routes,parent,producerFfmChecks,producerGaChecks,producerStates,user.oktaToken,agency`;
      }
    })(context);

    const response = yield call(getMethod, request, abortController.signal);
    yield put( getProducerSuccess(response));

  } catch (error) {
    yield put(getProducerFailed());
    const message = yield call(resolveErrorPromise, error);
    yield put(message?.detail ? flashErrorMessage(message.detail) : flashErrorMessage(FAILED_MESSAGE));
  } finally {
    if (yield cancelled()) {
      abortController.abort();
    }
  }
}

function* producerGet(context, action) {
  const abortController = new AbortController();

  try {
    const { agencyId, producerId } = action.payload;

    if (action.payload.callback) {
      yield fork(action.payload.callback, agencyId, producerId);
      yield race({
        success: take(SUCCESS(GET_PRODUCER_RESTRICTIONS_FORM_CONFIG)), //
        failed: take(FAILED(GET_PRODUCER_RESTRICTIONS_FORM_CONFIG)),
      }); // wait for succes response before continuing...
    }

    const request = ((condition) => {
      switch (condition) {
        case 'restrictions':
          return `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/v1/agency/${agencyId}/producer/${producerId}?include=person.proof,producerStates,producerRestrictedIssuers,producerApis.producerApiLineOfCoverages.apiLineOfCoverage.lineOfCoverage,producerApis.producerApiHiddenPlans,producerApis.api,producerFfmChecks,producerGaChecks,agentNumbers,agency`;
        case 'edit':
        default:
          return `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/v1/agency/${agencyId}/producer/${producerId}?include=person.proof,person.addresses,person.contacts,agentNumbers,user.roles.routes,parent,producerFfmChecks,producerGaChecks,producerStates,user.oktaToken,agency`;
      }
    })(context);

    const response = yield call(getMethod, request, abortController.signal);
    yield put(getProducerSuccess(response));

    if (context === 'edit') {
      yield put(getProducerPossibleParents({ agencyId, producerId }));
      if (!_.isEmpty(_.get(response, 'data.person.data.addresses.data.0.zipCode', false))) {
        yield put(getLocation({ zipCode: response.data.person.data.addresses.data[0].zipCode }));
      }
    }
  } catch (error) {
    yield put(getProducerFailed());
    const message = yield call(resolveErrorPromise, error);
    yield put(message?.detail ? flashErrorMessage(message.detail) : flashErrorMessage(FAILED_MESSAGE));
  } finally {
    if (yield cancelled()) {
      abortController.abort();
    }
  }
}

function* producerManage(action) {
  try {
    let response;
    if (action.payload.id.length > 0) {
      response = yield call(
        updateMethod,
        `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/agency/${action.payload.agencyId}/producer/${action.payload.id}?include=person,person.addresses,person.contacts,agentNumbers,user,user.roles.routes,parent,agency&notValidateFields=true&isAppAdmin=true`,
        JSON.stringify(action.payload)
      );
      if (!_.isEmpty(response.data.niprResponse)) {
        yield put(
          response.data.niprResponse.nipr.code === 200 && response.data.niprResponse.ffm.code === 200
            ? flashSuccessMessage(
                `For producer ${response.data.person.data.firstName} ${response.data.person.data.lastName} ${response.data.niprResponse.nipr.message}`,
                { timeout: 10000 }
              )
            : flashErrorMessage(
                `For producer ${response.data.person.data.firstName} ${response.data.person.data.lastName} ${response.data.niprResponse.nipr.message} and ${response.data.niprResponse.ffm.message}`,
                { timeout: 10000 }
              )
        );
      }
    } else {
      response = yield call(
        createMethod,
        `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/agency/${action.payload.agencyId}/producer?include=person,person.addresses,person.contacts,agentNumbers,user,user.roles.routes,parent,agency&notValidateFields=true&isAppAdmin=true`,
        JSON.stringify(action.payload)
      );
    }

    yield put(manageProducerSuccess(response));
  } catch (error) {
    const message = yield call(resolveErrorPromise, error);
    if (message?.detail?.formErrorMessages) {
      yield put(manageProducerFailed(message));
    } else {
      yield put(manageProducerFailed());

      yield put(message?.detail ? flashErrorMessage(message.detail) : flashErrorMessage(FAILED_MESSAGE));
    }
  }
}

function* producerRestrictionsManage(action) {
  try {
    const response = yield call(
      updateMethod,
      `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/producer-restrictions/${action.payload.id}?include=person,producerStates,producerRestrictedIssuers,producerApis.producerApiLineOfCoverages.apiLineOfCoverage.lineOfCoverage,producerApis.producerApiHiddenPlans,producerApis.api,producerFfmChecks,producerGaChecks`,
      JSON.stringify(action.payload)
    );

    yield put(manageProducerRestrictionsSuccess(response));
  } catch (error) {
    const message = yield call(resolveErrorPromise, error);
    if (message?.detail?.formErrorMessages) {
      yield put(manageProducerRestrictionsFailed(message));
    } else {
      yield put(manageProducerRestrictionsFailed());
      yield put(message?.detail ? flashErrorMessage(message.detail) : flashErrorMessage(FAILED_MESSAGE));
    }
  }
}

function* producerDelete(action) {
  try {
    yield call(
      patchMethod,
      `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/agency/${action.payload.agencyId}/producer/${action.payload.producerId}`,
      JSON.stringify({ isDeleted: action.payload.isDeleted })
    );
    yield put(deleteProducerSuccess());
    yield put(getProducerList(action.payload));
  } catch (error) {
    yield put(deleteProducerFailed());
    const message = yield call(resolveErrorPromise, error);
    yield put(message?.detail ? flashErrorMessage(message.detail) : flashErrorMessage(FAILED_MESSAGE));
  }
}

function* producerReactivate(action) {
  try {
    yield call(
      patchMethod,
      `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/agency/${action.payload.agencyId}/producer/${action.payload.producerId}`,
      JSON.stringify({ isDeleted: action.payload.isDeleted, status: 'Active' })
    );
    yield put(reactivateProducerSuccess());
    if (action?.payload?.getProducerList) {
      yield put(getProducerList(action.payload));
    }
  } catch (error) {
    yield put(reactivateProducerFailed());
    const message = yield call(resolveErrorPromise, error);
    yield put(message?.detail ? flashErrorMessage(message.detail) : flashErrorMessage(FAILED_MESSAGE));
  }
}

function* producerGetPossibleParents(action) {
  const request = `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/v1/agency/${action.payload.agencyId}/producer?include=person&child=${action.payload.producerId}`;
  try {
    const response = yield call(getMethod, request);
    yield put(getProducerPossibleParentsSuccess(response));
  } catch (error) {
    yield put(getProducerPossibleParentsFailed());
    const message = yield call(resolveErrorPromise, error);
    yield put(message?.detail ? flashErrorMessage(message.detail) : flashErrorMessage(FAILED_MESSAGE));
  }
}

function* producerApprove(action) {
  try {
    const response = yield call(
      getMethod,
      `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/approval/${action.payload.id}`
    );
    yield put(approveProducerSuccess(response));
  } catch (error) {
    const message = yield call(resolveErrorPromise, error);
    yield put(approveProducerFailed());
    yield put(flashErrorMessage(message.detail));
  }
}

function* producerNIPRApprove(action) {
  try {
    const response = yield call(
      getMethod,
      `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/v1/nipr-verify/${action.payload.id}`
    );
    yield put(approveProducerNIPRSuccess(response));
  } catch (error) {
    const message = yield call(resolveErrorPromise, error);
    yield put(approveProducerNIPRFailed());
    yield put(flashErrorMessage(message.detail));
  }
}

function* producerFFMApprove(action) {
  try {
    const response = yield call(
      getMethod,
      `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/v1/ffm-verify/${action.payload.id}`
    );
    yield put(approveProducerFFMSuccess(response));
  } catch (error) {
    const message = yield call(resolveErrorPromise, error);
    yield put(approveProducerFFMFailed());
    yield put(flashErrorMessage(message.detail));
  }
}

function* producerManualApprove(action) {
  try {
    const response = yield call(
      updateMethod,
      `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/approval/${action.payload.id}?include=person.proof,person.addresses,person.contacts,agentNumbers,user.roles.routes,parent,producerFfmChecks,producerGaChecks,producerStates,agency&isAppAdmin=true`,
      JSON.stringify(action.payload)
    );
    yield put(manualApproveProducerSuccess(response));
  } catch (error) {
    const message = yield call(resolveErrorPromise, error);

    if (message?.detail?.formErrorMessages) {
      yield put(manualApproveProducerFailed(message));
    } else {
      yield put(manualApproveProducerFailed());
      yield put(message?.detail ? flashErrorMessage(message.detail) : flashErrorMessage(FAILED_MESSAGE));
    }
  }
}

function* producerRestrictionsGetFormConfig(action) {
  const abortController = new AbortController();
  try {
    const request = `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/v1/producer-restrictions-form-config/${action.payload.agencyId}/${action.payload.producerId}`;

    const response = yield call(getMethod, request, abortController.signal);
    yield put(getProducerRestrictionsFormConfigSuccess(response));
  } catch (error) {
    const message = yield call(resolveErrorPromise, error);
    yield put(message?.detail ? flashErrorMessage(message.detail) : flashErrorMessage(FAILED_MESSAGE));
    yield put(getProducerRestrictionsFormConfigFailed());
  } finally {
    if (yield cancelled()) {
      abortController.abort();
    }
  }
}

function* agentRepProducerRestrictionsGetFormConfig(action) {
  const abortController = new AbortController();
  try {
    const request = `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/v1/agent-rep-producer-restrictions-form-config/${action.payload.agencyId}/${action.payload.producerId}?include=producerApis.api`;

    const response = yield call(getMethod, request, abortController.signal);
    yield put(getAgentRepProducerRestrictionsFormConfigSuccess(response));
  } catch (error) {
    const message = yield call(resolveErrorPromise, error);
    yield put(message?.detail ? flashErrorMessage(message.detail) : flashErrorMessage(FAILED_MESSAGE));
    yield put(getAgentRepProducerRestrictionsFormConfigFailed());
  } finally {
    if (yield cancelled()) {
      abortController.abort();
    }
  }
}

function* producerTemplateImportCsv(action) {
  try {
    const response = yield call(
      createMethod,
      `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}/producer-template-csv`,
      JSON.stringify(action.payload)
    );

    yield put(producerTemplateImportCsvSuccess(response));
    yield put(flashSuccessMessage('Import successful.'));
  } catch (error) {
    const message = yield call(resolveErrorPromise, error);
    if (message?.detail?.formErrorMessages) {
      yield put(producerTemplateImportCsvFailed(message.detail));
      yield put(clearMessages());
      yield put(flashErrorMessage(message.detail, { timeout: 30000 }));
    } else {
      yield put(producerTemplateImportCsvFailed());
      yield put(flashErrorMessage(message.detail, { timeout: 30000 }));
    }
  }
}

function* copyToClipboard(action) {
  yield put(flashSuccessMessage(action.payload));
}

export function* watchGetProducerList() {
  yield takeLeading(GET_PRODUCER_LIST, producerGetList);
}

export function* watchGetAllProducersList() {
  yield takeLeading(GET_ALL_PRODUCERS_LIST, allProducerGetList);
}

export function* watchGetAllProducersAndAgenciesGetList() {
  yield takeLeading(GET_ALL_PRODUCERS_AND_AGENCIES_LIST, allProducersAndAgenciesGetList);
}

export function* watchGetProducer() {
  yield takeLatest(GET_PRODUCER, producerGet, 'edit');
}

export function* watchGetProducerRestrictions() {
  yield takeLatest(GET_PRODUCER_RESTRICTIONS, producerGet, 'restrictions');
}

export function* watchManageProducer() {
  yield takeLeading(MANAGE_PRODUCER, producerManage);
}

export function* watchDeleteProducer() {
  yield takeLeading(DELETE_PRODUCER, producerDelete);
}

export function* watchReactivateProducer() {
  yield takeLeading(REACTIVATE_PRODUCER, producerReactivate);
}

export function* watchGetProducerPossibleParents() {
  yield takeLeading(GET_PRODUCER_POSSIBLE_PARENTS, producerGetPossibleParents);
}

export function* watchProducerApprove() {
  yield takeLatest(APPROVE_PRODUCER, producerApprove);
}

export function* watchProducerNIPRApprove() {
  yield takeLatest(APPROVE_PRODUCER_NIPR, producerNIPRApprove);
}

export function* watchProducerFFMApprove() {
  yield takeLatest(APPROVE_PRODUCER_FFM, producerFFMApprove);
}

export function* watchProducerManualApprove() {
  yield takeLatest(MANUAL_APPROVE_PRODUCER, producerManualApprove);
}

export function* watchGetProducerRestrctionsFormConfig() {
  yield takeLeading(GET_PRODUCER_RESTRICTIONS_FORM_CONFIG, producerRestrictionsGetFormConfig);
}

export function* watchGetAgentRepProducerRestrictionsGetFormConfig() {
  yield takeLeading(GET_AGENT_REP_PRODUCER_RESTRICTIONS_FORM_CONFIG, agentRepProducerRestrictionsGetFormConfig);
}

export function* watchGetAgentRepProducerRestrictions() {
  yield takeLatest(GET_AGENT_REP_PRODUCER_RESTRICTIONS, agentRepProducerGet, 'restrictions');
}

export function* watchManageProducerRestrictions() {
  yield takeLeading(MANAGE_PRODUCER_RESTRICTIONS, producerRestrictionsManage);
}

export function* watchCopyToClipboard() {
  yield takeLeading(COPY_TO_CLIPBOARD, copyToClipboard);
}

export function* watchProducerTemplateImportCsv() {
  yield takeLeading(PRODUCER_TEMPLATE_IMPORT_CSV, producerTemplateImportCsv);
}

function* producersSaga() {
  while (true) {
    const tasks = yield all([
      fork(watchGetProducerList),
      fork(watchGetAllProducersList),
      fork(watchGetAllProducersAndAgenciesGetList),
      fork(watchDeleteProducer),
      fork(watchGetProducer),
      fork(watchManageProducer),
      fork(watchReactivateProducer),
      fork(watchGetProducerPossibleParents),
      fork(watchProducerApprove),
      fork(watchProducerNIPRApprove),
      fork(watchProducerFFMApprove),
      fork(watchProducerManualApprove),
      fork(watchGetProducerRestrctionsFormConfig),
      fork(watchGetProducerRestrictions),
      fork(watchManageProducerRestrictions),
      fork(watchCopyToClipboard),
      fork(watchProducerTemplateImportCsv),
      fork(watchGetAgentRepProducerRestrictionsGetFormConfig),
      fork(watchGetAgentRepProducerRestrictions),
    ]);

    yield take(RESET);

    yield cancel(tasks);
  }
}

export default producersSaga;
